import {WorkspaceType} from "@/dto/auth/Workspace";

export default class ProfileDTO{

    public id!: number;

    public type!: WorkspaceType;

    public name!: string;

    public tin: string | null = null;

    public email: string | null = null;
}