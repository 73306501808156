









































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { debounce } from 'typescript-debounce-decorator';
import ProfileDTO from "@/dto/profile/ProfileDTO";
import ProfileFilterDTO from "@/dto/profile/ProfileFilterDTO";
import ProfileService from "@/services/ProfileService";
import { WorkspaceType } from "@/dto/auth/Workspace";
import {processError} from "@/utils/ComponentUtils";

@Component({
  computed: {
    WorkspaceType() {
      return WorkspaceType;
    }
  }
})
export default class SimpleProfileSearch extends Vue {

  @Model('change', { type: ProfileDTO, default: null })
  private selectedProfile!: ProfileDTO | null;

  @Prop({ default: "profiles" })
  private name!: string;

  @Prop({ default: false })
  private required!: boolean;

  @Prop()
  private label!: string | null;

  private filter = new ProfileFilterDTO();
  private profiles: Array<ProfileDTO> = [];

  private showList = false;

  private dropUp = false;

  mounted() {
    if (this.selectedProfile) {
      this.filter.searchQuery =
          this.selectedProfile.name + ' ' +
          this.selectedProfile.tin + ' ' +
          this.selectedProfile.email;
    }
  }

  @debounce(200)
  private search() {
    ProfileService.getByFilter(this.filter).then(
        ok => {
          this.profiles = ok.data;
          this.showList = true;
          this.$nextTick(() => {
            this.updateDropDownPosition();
          });
        },
        err => processError(err, this)
    );
  }

  @Prop({default: null})
  private onSelectProfile!: ((profile: ProfileDTO) => void) | null;

  selectProfile(p: ProfileDTO) {
    this.showList = false;
    this.filter.searchQuery = p.name + ' ' + p.tin + ' ' + p.email;

    this.$emit('change', p);

    if (this.onSelectProfile) {
      this.onSelectProfile(p);
    }
  }

  @Watch("selectedProfile")
  watchProfile(_new: ProfileDTO | null, old: ProfileDTO | null) {
    if(_new == null){
      this.filter.searchQuery = null;
    }
  }

  private updateDropDownPosition() {

    const inputEl = this.$el.querySelector('input');

    const autocompleteEl = this.$refs.autocomplete as HTMLElement;

    if (!inputEl || !autocompleteEl) return;

    const inputRect = inputEl.getBoundingClientRect();

    const windowHeight = window.innerHeight;

    const spaceBelow = windowHeight - inputRect.bottom;

    const dropdownHeight = autocompleteEl.offsetHeight;

    if (spaceBelow < dropdownHeight) {
      this.dropUp = true;
    } else {
      this.dropUp = false;
    }
  }
}
